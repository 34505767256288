
import React from "react"

import NnreturnPage from "../../components/negosyonow/controls/nnreturneditor";

const SellerreturnPage = ({location}) => {
	return <NnreturnPage
				location={location}
				allowadd={false}
				methodid={0}
				encodertype={2}
			/>
}


export default SellerreturnPage;
